import { Link, useSearchParams } from "react-router-dom"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import QrCode from "../../../common/containers/QrCode";
import { callSupportPost } from "../actions/login";

let Dashboard = ({ setBreadcrumbs, methods: { callSupportPost } }) => {

    const [showQrCode, setShowQrCode] = useState(false);
    const [searchParams] = useSearchParams();

    const readQrCode = () => {
        setShowQrCode(true);
    }

    const onQrCodeResult = (result, error) => {
        if (result) {
            callSupportPost({
                secret: result,
                redirect: false
            })
        }
    }

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Dashboard', active: true }
        ])
    }, [setBreadcrumbs])

    useEffect(() => {
        if (searchParams.get('secret')) {
            callSupportPost({
                secret: searchParams.get('secret'),
                redirect: true
            })
        }
    }, [searchParams, callSupportPost])

    return (
        <>
            {showQrCode ? (
                <>
                    <div className='qrcode-overlay'></div>
                    <QrCode onClose={_ => setShowQrCode(false)} onResult={onQrCodeResult} />
                </>
            ) : (
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h2 className="card-title">Dashboard</h2>
                                    <p className="card-text">Bem vindo</p>
                                </div>
                            </div>
                            <ul className="nav nav-tabs mt-4">
                                <li className="nav-item">
                                    <Link className='nav-link active' to='/admin'>Informações</Link>
                                </li>
                            </ul>
                            <div className="tabcontent-border">
                                <div className='row'>
                                    <div className="col-md-3">
                                        <button className='btn btn-secondary btn-block' onClick={_ => readQrCode()}>
                                            <i className="fas fa-qrcode"></i><br />
                                            Autorizar Suporte
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callSupportPost
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)