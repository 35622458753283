import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callUserViewGet, callUserPost, callUserPut } from "../../actions/users"
import Sidebar from "../../../../../common/containers/Sidebar"
import Card from "../../../../../common/containers/Card"

let UserEdit = ({ methods: { callUserPost, callUserViewGet, callUserPut }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [passConfirm, setPassConfirm] = useState('')
    const [pageAttr, setPageAttr] = useState({})

    useEffect(() => {
        let tabs
        if (!id) {
            tabs = [
                {
                    active: true,
                    to: '/register/user/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callUserViewGet(id)
            tabs = [
                {
                    active: true,
                    to: '/register/user/edit/' + id,
                    label: 'Editar'
                }
            ]
        }

        setPageAttr({
            title: 'Usuário - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callUserViewGet])

    useEffect(() => {
        setName(view.name)
        setEmail(view.email)
    }, [view])

    const finishedSubmit = () => {
        setName('')
        setEmail('')
        setPass('')
        setPassConfirm('')
        window.navigate('/register/user')
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            name,
            email,
            pass,
            passConfirm
        }

        if (!id) {
            callUserPost(data, () => finishedSubmit())
        } else {
            callUserPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <Sidebar pWidth="50%">
            <Card {...pageAttr} showClose={false}>
                <form onSubmit={submit}>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-6 form-group">
                                    <label className="required">Nome:</label>
                                    <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="required">E-mail:</label>
                                    <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="required">Senha:</label>
                                    <input type="password" className="form-control" value={pass} onChange={e => setPass(e.target.value)} />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label className="required">Confirmar Senha:</label>
                                    <input type="password" className="form-control" value={passConfirm} onChange={e => setPassConfirm(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix text-left mt-3">
                        <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                        <Link to='/register/user' className='btn btn-secondary ml-2'>Cancelar</Link>
                    </div>
                </form>
            </Card>
        </Sidebar>
    )
}

const mapStateToProps = ({ register: { users: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callUserViewGet,
            callUserPost,
            callUserPut,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)