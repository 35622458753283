import { useCallback, useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";

let QrCode = ({ onClose, onResult }) => {

    // QR States
    const scanner = useRef();
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const [qrOn, setQrOn] = useState(true);

    const internalClose = useCallback(() => {
        if (scanner.current) {
            scanner.current.stop();
        }
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    // Success
    const onScanSuccess = useCallback((result) => {
        // 🖨 Print the "result" to browser console.
        console.log(result);
        // ✅ Handle success.
        // 😎 You can do whatever you want with the scanned result.
        if (onResult) {
            onResult(result?.data);
            internalClose()
        }
    }, [onResult, internalClose]);

    // Fail
    const onScanFail = useCallback((err) => {
        // 🖨 Print the "err" to browser console.
        if (onResult) {
            onResult(null, err);
        }
    }, [onResult]);

    useEffect(() => {
        let videoCurrent = videoEl?.current;
        if (videoCurrent && !scanner.current) {
            // 👉 Instantiate the QR Scanner
            scanner.current = new QrScanner(videoCurrent, onScanSuccess, {
                onDecodeError: onScanFail,
                // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
                preferredCamera: "environment",
                // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
                highlightScanRegion: true,
                // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
                highlightCodeOutline: true,
                // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
                overlay: qrBoxEl?.current || undefined,
            });

            // 🚀 Start QR Scanner
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        // 🧹 Clean up on unmount.
        // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
        return () => {
            if (!videoCurrent) {
                scanner?.current?.stop();
            }
        };
    }, [onScanSuccess, onScanFail]);

    // ❌ If "camera" is not allowed in browser permissions, show an alert.
    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    return (
        <div className='qr-reader-over'>
            <div className="qr-reader">
                <div className='qr-title'>APONTE PARA O QRCODE</div>
                <video ref={videoEl} width={200} height={200}></video>
                <div>
                    <button type="button" className='btn btn-primary btn-block' onClick={_ => internalClose()}>Fechar</button>
                </div>
            </div>
        </div>
    )
}

export default QrCode