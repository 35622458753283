import { Link } from "react-router-dom"
import './../styles/Breadcrumb.scss'

let Breadcrumb = ({items = []}) => {
    if(items.length === 0){
        return null
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <nav>
                        <ol className='breadcrumb m-0'>
                            {items.map((a, ai) => {
                                if (a.active === undefined) {
                                    a.active = false
                                }
                                return (
                                    <li key={ai} className={'breadcrumb-item' + (a.active ? ' active' : '')}>
                                        {a.active ? a.label : <Link to={a.href}>{a.label}</Link>}
                                    </li>
                                )
                            }
                            )}
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumb;