import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let callSupportPost = (data) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/support', data).then(data => {
        dispatch(callLoader(false))
        if (!data)
            return;

        if(data?.redir){
            document.location.href = data.redir
        }
    })
}

export { callSupportPost }