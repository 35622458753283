import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callEnvironmentViewGet, callEnvironmentPost, callEnvironmentPut } from "../../actions/environments"
import { callCustomerSelectGet } from './../../../register/actions/customers'
import { softwares } from "../../../../../constants"
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Sidebar from "../../../../../common/containers/Sidebar"
import Card from "../../../../../common/containers/Card"

let EnvironmentEdit = ({ methods: { callEnvironmentPost, callEnvironmentViewGet, callEnvironmentPut, callCustomerSelectGet }, view, customers }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [software, setSoftware] = useState([])
    const [customer, setCustomer] = useState([])
    const [domain, setDomain] = useState('')
    const [pageAttr, setPageAttr] = useState({})

    useEffect(() => {
        callCustomerSelectGet()

        let tabs
        if (!id) {
            tabs = [
                {
                    active: true,
                    to: '/software/environment/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callEnvironmentViewGet(id)
            tabs = [
                {
                    active: true,
                    to: '/software/environment/edit/' + id,
                    label: 'Editar'
                }
            ]
        }

        setPageAttr({
            title: 'Ambiente - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callEnvironmentViewGet, callCustomerSelectGet])

    useEffect(() => {
        setSoftware([view.software])
        setCustomer([view.customer])
        setDomain(view.domain)
    }, [view])

    const finishedSubmit = () => {
        setSoftware([])
        setCustomer([])
        setDomain('')
        window.navigate('/software/environment')
    }

    const submit = (e) => {
        e.preventDefault()

        let customerId = null
        if (customer.length === 1) {
            customerId = customer[0].id
        }

        let data = {
            software: softwares[0].id ?? null,
            customerId,
            domain
        }

        if (!id) {
            callEnvironmentPost(data, () => finishedSubmit())
        } else {
            callEnvironmentPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <Sidebar pWidth="80%">
            <Card {...pageAttr} closeTo='/software/environment'>
                <form onSubmit={submit}>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-4 form-group">
                                    <label className="required">Software:</label>
                                    <Typeahead
                                        id="software"
                                        labelKey="name"
                                        onChange={(e) => setSoftware(e)}
                                        options={softwares}
                                        placeholder="Selecione o software"
                                        selected={software}
                                    />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label className="required">Cliente:</label>
                                    <Typeahead
                                        id="customer"
                                        labelKey="name"
                                        onChange={(e) => setCustomer(e)}
                                        options={customers}
                                        placeholder="Selecione o cliente"
                                        selected={customer}
                                    />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label className="required">Domínio:</label>
                                    <input type="text" className="form-control" value={domain} onChange={e => setDomain(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix text-left mt-3">
                        <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                    </div>
                </form>
            </Card>
        </Sidebar>
    )
}

const mapStateToProps = ({ software: { environments: { view } }, register: { customers } }) => ({
    view: view,
    customers: customers.select
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callEnvironmentViewGet,
            callEnvironmentPost,
            callEnvironmentPut,
            callCustomerSelectGet
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentEdit)