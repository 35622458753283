import { Routes, Route, useNavigate } from 'react-router-dom'
import { Provider } from "react-redux";
import storeAdmin from "./admin/store";
import AdminMiddleware from "./admin/middleware";
import AdminLogin from './admin/modules/account/containers/Login';

function App() {
  window.navigate = useNavigate()
  return (
    <Routes>
      {/* Routes Admin */}
      <Route path="/account/login" exact element={
        <Provider store={storeAdmin}>
          <AdminMiddleware forceAuth={false} exact={true} header={null}>
            <AdminLogin />
          </AdminMiddleware>
        </Provider>} />
      <Route path="/*" index element={<Provider store={storeAdmin}><AdminMiddleware forceAuth={true} exact={true} /></Provider>} />

    </Routes>
  );
}

export default App